import { useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import { Box, Button } from "@mui/material";
// components
import Iconify from "../Iconify";
import useLocales from "src/hooks/useLocales";

// ----------------------------------------------------------------------

export default function SettingFullscreen() {
  const { translate } = useLocales();
  const [fullscreen, setFullscreen] = useState(false);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setFullscreen(false);
    }
  };

  return (
    <Box>
      <Button
        fullWidth
        size="large"
        variant="outlined"
        color={fullscreen ? "primary" : "inherit"}
        startIcon={
          <Iconify
            icon={
              fullscreen ? "ic:round-fullscreen-exit" : "ic:round-fullscreen"
            }
          />
        }
        onClick={toggleFullScreen}
        sx={{
          fontSize: 14,
          ...(fullscreen && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.selectedOpacity
              ),
          }),
        }}
      >
        {fullscreen
          ? translate("settingsHeader.exitFullscreen")
          : translate("settingsHeader.fullscreen")}
      </Button>
    </Box>
  );
}
