import { lazy } from "react";
import Loadable from "../Loadable";
import { Navigate } from "react-router";

const Package = Loadable(lazy(() => import("src/pages/user/package/index")));
const MyOrders = Loadable(
  lazy(() => import("src/pages/user/package/component/myOrders/index.jsx"))
);
const ViewOrderById = Loadable(
  lazy(() =>
    import("src/pages/user/package/component/myOrders/orderView/index.jsx")
  )
);

const packages = {
  path: "plan",
  children: [
    { index: true, element: <Navigate to="package" /> },
    { path: "package", element: <Package /> },
    {
      path: "invoices",
      children: [
        { index: true, element: <MyOrders /> },
        {
          path: ":id",
          element: <ViewOrderById />,
        },
      ],
    },
  ],
};
export default packages;
