import { lazy } from "react";
import { Navigate } from "react-router";
import CourseHistory from "src/pages/admin/course/courseHistory";
// import Documents from "src/pages/admin/course/materials/subPages/documents";
// import Videos from "src/pages/admin/course/materials/subPages/videos.js";
import Loadable from "src/routes/Loadable";

const Courses = Loadable(
  lazy(() => import("src/pages/admin/course/course/index"))
);
const Category = Loadable(
  lazy(() => import("src/pages/admin/course/category/index"))
);
const AddCourser = Loadable(
  lazy(() => import("src/pages/admin/course/course/CreateCourse"))
);

const EditCourse = Loadable(
  lazy(() => import("src/pages/admin/course/course/EditCourse"))
);
const CourseVideo = Loadable(
  lazy(() => import("src/pages/admin/course/course/course-video-doc/video"))
);
const CourseDocument = Loadable(
  lazy(() => import("src/pages/admin/course/course/course-video-doc/doc"))
);
const CourseReviews = Loadable(
  lazy(() => import("src/pages/admin/course/userReviews/index"))
);
const CourseReviewsAdd = Loadable(
  lazy(() => import("src/pages/admin/course/userReviews/reviewAdd"))
);
const CourseReviewsView = Loadable(
  lazy(() => import("src/pages/admin/course/userReviews/reviewView"))
);

const course = [
  {
    path: "course",
    children: [
      { element: <Navigate to="course" />, index: true },
      {
        path: "courses",
        children: [
          { index: true, element: <Courses /> },
          { path: "add", element: <AddCourser /> },
          { path: ":id", element: <EditCourse /> },
          { path: "video/:id", element: <CourseVideo/> },
          { path: "doc/:id", element: <CourseDocument /> },
        ],
      },
      { path: "category", element: <Category /> },
      { path: "coursesHistory", element: <CourseHistory /> },
      {
        path: "reviews",
        children: [
          { index: true, element: <CourseReviews /> },
          { path: "add", element: <CourseReviewsAdd /> },
          { path: ":rid", element: <CourseReviewsView /> },
        ],
      },
    ],
  },
];
export default course;
