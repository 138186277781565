import { Box } from "@mui/material";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import useLocales from "src/hooks/useLocales";
import { PATH_DASHBOARD } from "src/routes/paths";
import DataTable from "./components/dataTable";

const CourseHistory = () => {
	const { translate } = useLocales();
	return (
		<Page title="Course History">
			<Box sx={{ p: 2 }}>
				<HeaderBreadcrumbs heading="Course History" links={[{ name: translate("dashboard"), href: PATH_DASHBOARD.root }, { name: "Course History" }]} />
				<DataTable />
			</Box>
		</Page>
	);
};
export default CourseHistory;
