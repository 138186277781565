import { useEffect, useState } from "react";
import usePagination from "src/components/pagination/usePagination";
import useErrors from "src/hooks/useErrors";
import axiosInstance from "src/utils/axios";

const useFetchCourseHistory = (filter) => {
	const [data, setData] = useState([]);
	const handleErrors = useErrors();

	const { count, onChange, page, seed, rowStart } = usePagination();

	const fetchData = async (page = 1, filter) => {
		try {
			const { data } = await axiosInstance.get("/api/admin/course-history", {
				params: {page, ...filter },
			});

			const { status, data: course } = data;

			if (status) {
				seed(course.last_page, course.from);
                onChange(null, page);
				setData(course.data);
			}

		} catch (err) {
			handleErrors(err);
		}
	};

	useEffect(() => {
		fetchData(page, filter);
	}, [page]);

	return { data, fetchData, count, onChange, page, rowStart };
};

export default useFetchCourseHistory;
