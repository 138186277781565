import { Card, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import PaginationButtons from "src/components/pagination";

import Scrollbar from "src/components/Scrollbar";

import useFetchCourseHistory from "../hooks/useFetchCourseHistory";
import useLocales from "src/hooks/useLocales";
import EmptyTable from "src/components/emptyTable";
import { useForm } from "react-hook-form";
import DataFilter from "src/pages/admin/financial/shared/dataFilter";
import { Currency } from "src/components/with-prefix";

export const defaultValues = {
	start_date: "",
	end_date: "",
	user_id: null,
};

const DataTable = () => {
	const methods = useForm({ defaultValues });

	const filter = methods.watch();

	const { translate } = useLocales();

	const { data, fetchData, rowStart, ...rest } = useFetchCourseHistory(filter);
    
	const isEmpty = !Boolean(data?.length);

	const onFilter = methods.handleSubmit((inputData) => {
		fetchData(1, inputData);
	});

	return (
		<>
			<Card>
				<DataFilter methods={methods} onFilter={onFilter} />
				<Scrollbar>
					<TableContainer sx={{ minWidth: 720 }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>{translate("adminFinancial.Ewallet.no")}</TableCell>
									<TableCell>{translate("adminFinancial.Ewallet.userName")}</TableCell>
									<TableCell>Course name</TableCell>
									<TableCell>{translate("adminFinancial.Ewallet.amount")}</TableCell>
									<TableCell>Payment status</TableCell>
									<TableCell>{translate("adminFinancial.Ewallet.date")}</TableCell>
									<TableCell />
								</TableRow>
							</TableHead>
							{isEmpty ? (
								<TableRow>
									<TableCell sx={{ textAlign: "center" }} colSpan={12}>
										<EmptyTable title="No Data Available" />
									</TableCell>
								</TableRow>
							) : (
								<TableBody>
									{data.map((item, i) => (
										<TableRow key={item.id}>
											<TableCell>{i + rowStart}</TableCell>
											<TableCell>{item.user?.username}</TableCell>
											<TableCell>{item.course?.name}</TableCell>
											<TableCell>
												<Currency>{item?.amount}</Currency>
											</TableCell>
											<TableCell>{item.status}</TableCell>
											<TableCell>{new Date(item.created_at).toLocaleDateString("en-GB")}</TableCell>
										</TableRow>
									))}
								</TableBody>
							)}
						</Table>
					</TableContainer>
				</Scrollbar>
				<Divider />
			</Card>
			<PaginationButtons {...rest} />
		</>
	);
};

export default DataTable;
