import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import EmptyContent from "src/components/EmptyContent";
import Oops from "src/images/oops.png";
import Ternary from "../ternary";

const EmptyTable = ({ error = false }) => {
  const { palette } = useTheme();

  return (
    <Box>
      <Ternary
        when={error}
        then={
          <Box sx={{ p: 4 }}>
            <Box
              sx={{ justifyContent: "center", display: "flex", mt: 5, mb: 2 }}
            >
              <img src={Oops} width="70" />
            </Box>
            <Typography
              variant="h3"
              sx={{ color: palette.primary.dark, textAlign: "center" }}
            >
              Oops !!!
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: palette.primary.dark, textAlign: "center" }}
            >
              something went wrong please try again later
            </Typography>
          </Box>
        }
        otherwise={
          <EmptyContent
            title={
              <Typography variant="subtitle2">No Data Available</Typography>
            }
            sx={{
              "& span.MuiBox-root": { height: 160 },
              color: "#b2b2b2",
            }}
          />
        }
      />
    </Box>
  );
};

export default EmptyTable;
