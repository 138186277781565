import { Autocomplete, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import useUsersList from "src/components/autoComplete/users/hooks/useUsersList";
import useLocales from "src/hooks/useLocales";

const Users = ({name,...rest}) => {
  const { translate } = useLocales();
  const { onSearch, usersList } = useUsersList();

  const {
    watch,
    formState: { errors },
    setValue,
  } = useFormContext();
  const userId = watch("user_id");
  const selected = usersList.find(({ user_id }) => user_id === userId) || null;
  return (
    <Autocomplete
      value={selected}
      onChange={(_, v) => setValue("user_id", v ? v.user_id : null)}
      disablePortal
      id="users"
      options={usersList}
      getOptionLabel={(item) => item.username}
      renderInput={(params) => (
        <TextField
          {...params}
          
          label={translate("adminCommunication.helpCenter.users")}
          placeholder={translate("adminCommunication.helpCenter.startTyping")}
          error={Boolean(errors.user_id)}
          helperText={errors.user_id?.message}
          onChange={onSearch}
        />
      )}
      {...rest}
    />
  );
};

export default Users;
