import { lazy } from "react";
import Loadable from "src/routes/Loadable";
const Package = Loadable(lazy(() => import("src/pages/admin/package/index")));

const CreatePackage = Loadable(
  lazy(() => import("src/pages/admin/package/component/create-package"))
);
const EditPackage = Loadable(
  lazy(() => import("src/pages/admin/package/component/edit-package"))
);

const packages = [
  {
    path: "package",
    children: [
      { index: true, element: <Package /> },
      { path: "add", element: <CreatePackage /> },
      { path: ":id", element: <EditPackage /> },
    ],
  },
];
export default packages;
