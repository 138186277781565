const convertDMYToMDY = (dateToConvert) => {
  const [date, month, year] = dateToConvert.split("/");
  return [month, date, year].join("/");
};

export const convertMDYToDMY = (dateToConvert) => {
  const [month, date, year] = dateToConvert.split("/");
  return [date, month, year].join("/");
};

export const convertDMYToYMD = (dateToConvert) => {
    const [date, month, year] = dateToConvert.split("/");
    return [year, month, date].join("-");
};

export const convertYMDToDMY = (dateToConvert) => {
    const [year, month, date] = dateToConvert.split("-");
    return [date, month, year].join("/");
};

export default convertDMYToMDY;
