import { lazy } from "react";
import Loadable from "../Loadable";
// import { Navigate } from "react-router";
// import ViewMycourse from "src/pages/user/courses/Mycourse/ViewMycourse";

const ViewMycourse = Loadable(lazy(() => import("src/pages/user/courses/Mycourse/ViewMycourse")));

const Courses = Loadable(lazy(() => import("src/pages/user/courses/index")));

const CourseDetails = Loadable(lazy(() => import("src/pages/user/courses/details/index")));

const CoursePurchase = Loadable(lazy(() => import("src/pages/user/courses/coursePurchase/index")));

const MyCourse = Loadable(lazy(() => import("src/pages/user/courses/Mycourse/index")));

const MycourseHistory = Loadable(lazy(() => import("src/pages/user/courses/transactionHistory/index")));

// const MyOrders = Loadable(
//   lazy(() => import("src/pages/user/courses/myOrders/index.jsx"))
// );

const ViewOrderById = Loadable(lazy(() => import("src/pages/user/courses/myOrders/orderView/index.jsx")));

const courses = {
	path: "course",
	children: [
		{
			path: "courses",
			children: [
				{ index: true, element: <Courses /> },
				{ path: ":id", element: <CoursePurchase /> },
				{ path: "view/:id", element: <CourseDetails /> },
			],
		},
		{
			path: "mycourses",
			children: [
				{ index: true, element: <MyCourse /> },
				{ path: ":id", element: <ViewMycourse /> },
			],
		},
		// { path: "mycourseHistory", element: <MycourseHistory /> },
		{
			path: "mycourseHistory",
			children: [
				{ index: true, element: <MycourseHistory /> },
				{
					path: ":id",
					element: <ViewOrderById />,
				},
			],
		},
	],
};
export default courses;
