import { lazy } from "react";
import Loadable from "src/routes/Loadable";

const Invoice = Loadable(
  lazy(() => import("src/pages/invoice/myOrders/index"))
);

const invoice = [
  { path: "invoice/:id", element: <Invoice /> },
];

export default invoice;
